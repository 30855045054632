// src/pages/file/Open.js
import React from 'react';
import Content from '../../components/Content';
import fileOpenImg from '../../images/file/file_open.jpg';
import launchAppImg from '../../images/file/launch_app.jpg';
import selectFileImg from '../../images/file/select_file.jpg';
import sceneImg from '../../images/file/scene.jpg';

function Open() {
    return (
        <Content title="Open File Internally">
            <h3>Open an STL file from the app's internal file manager</h3>
            <p>Launch the app</p>
            <img src={launchAppImg} alt="launch app" />
            <p>Go to File &rarr; Open</p>
            <img src={fileOpenImg} alt="open file" />
            <p>Browse the phone storage and select an STL file</p>
            <img src={selectFileImg} alt="open file select" />
            <p>Get the selected file loaded on the scene</p>
            <img src={sceneImg} alt="open file select loaded" />
        </Content>
    );
}

export default Open;