import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import View from './pages/View';
import Mode from './pages/Mode';
import Separate from './pages/tools/Separate';
import Open from './pages/file/Open';
import OpenExternally from './pages/file/OpenExternally';
import './styles.css';
import { analytics, logEvent } from './firebase';

function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = document.title || 'Unknown Page';
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: pageTitle,
    });
    console.log(`Page view logged: ${pageTitle} (${location.pathname})`);
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <div className="app">
        <Sidebar />
        <div className="content-area">
          <PageViewTracker />
          <Routes>
            <Route path="/file/open/internally" element={<Open />} />
            <Route path="/file/open/externally" element={<OpenExternally />} />
            <Route path="/view/orientation" element={<View />} />
            <Route path="/view/mode" element={<Mode />} />
            <Route path="/tools/unconnected" element={<Separate />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<div>404: Page Not Found</div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;