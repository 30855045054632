import React from 'react';
import Content from '../components/Content';
import viewImg from '../images/view/view.jpg';
import viewAxesImg from '../images/view/view_axes.jpg';
import viewTopImg from '../images/view/view_top.jpg';
import viewBottomImg from '../images/view/view_bottom.jpg';
import viewLeftImg from '../images/view/view_left.jpg';
import viewRightImg from '../images/view/view_right.jpg';
import viewFrontImg from '../images/view/view_front.jpg';
import viewBackImg from '../images/view/view_back.jpg';

function View() {
    return (
        <Content title="View Orientation">
            <h3>Select one of the 6 view modes to orient the model(s) in the scene</h3>
            <img src={viewImg} alt="view menu" />
            <p>At the bottom of the scene: X-axis is red, Y-axis is green, Z-axis is blue</p>
            <img src={viewAxesImg} alt="world coordinate axes" />
            <p>Top view: XY-plane, Z-axis points to the viewer</p>
            <img src={viewTopImg} alt="view from top" />
            <p>Bottom view: XY-plane, Z-axis points from the viewer</p>
            <img src={viewBottomImg} alt="view from bottom" />
            <p>Left view: ZY-plane, X-axis points from the viewer</p>
            <img src={viewLeftImg} alt="view from left" />
            <p>Right view: ZY-plane, X-axis points to the viewer</p>
            <img src={viewRightImg} alt="view from right" />
            <p>Front view: ZX-plane, Y-axis points from the viewer</p>
            <img src={viewFrontImg} alt="view from front" />
            <p>Back view: ZX-plane, Y-axis points to the viewer</p>
            <img src={viewBackImg} alt="view from back" />
        </Content>
    );
}

export default View;