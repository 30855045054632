import React from 'react';
import Content from '../../components/Content';
import split1Img from '../../images/tools/split1.jpg'
import split2Img from '../../images/tools/split2.jpg'
import split3Img from '../../images/tools/split3.jpg'
import split4Img from '../../images/tools/split4.jpg'
import split5Img from '../../images/tools/split5.jpg'
import split6Img from '../../images/tools/split6.jpg'

function Separate() {
    return (
        <Content title="Find Unconnected Parts">
            <h3>Use this operation to find unconnected parts in the open model</h3>
            <p>Open an STL file</p>
            <img src={split1Img} alt="view modes" />
            <p>Select the model by holding a finger on it for one second. The model is successfully selected if a white bounding box appears around it</p>
            <img src={split2Img} alt="view modes" />
            <p>Go to Tools &rarr; Separate</p>
            <img src={split3Img} alt="view modes" />
            <p>If the model has unconnected parts, they will be displayed in distinct colors</p>
            <img src={split4Img} alt="view modes" />
            <p>Now, each part can be selected separately</p>
            <img src={split5Img} alt="view modes" />
            <p>Alternatively</p>
            <img src={split6Img} alt="view modes" />
        </Content>
    );
}

export default Separate;