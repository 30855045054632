import React from 'react';
import fsvGooglePlayImg from '../images/fsv_mobile.png'

function AppDownload() {
    return (
        <div>
            <a
                href="https://play.google.com/store/apps/details?id=com.performance.meshview"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={fsvGooglePlayImg} alt="app stats on google play" style={{ maxWidth: '400px' }} />
            </a>
        </div>
    );
}

export default AppDownload;
