// src/pages/file/Open.js
import React from 'react';
import Content from '../../components/Content';
import launchFileManagerImg from '../../images/file/external_file_manager.jpg';
import stlFilesImg from '../../images/file/file_manager_navigation.jpg';
import defaultAppImg from '../../images/file/open_with_app.jpg';
import sceneImg from '../../images/file/scene.jpg';

function OpenExternally() {
    return (
        <Content title="Open File Externally">
            <h3>Open an STL file from an external file manager</h3>
            <p>Launch an external file manager</p>
            <img src={launchFileManagerImg} alt="launch app" />
            <p>Navigate to a folder with STL files and select an STL file</p>
            <img src={stlFilesImg} alt="launch app" />
            <p>Select a default application to open STL files</p>
            <img src={defaultAppImg} alt="launch app" />
            <p>Get the selected file loaded on the scene</p>
            <img src={sceneImg} alt="open file select loaded" />
        </Content>
    );
}

export default OpenExternally;