import React from 'react';
import Content from '../components/Content';
import AppDownload from '../components/AppDownload';

function Home() {
    return (
        <Content >
            <AppDownload />
        </Content>
    );
}

export default Home;