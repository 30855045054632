import React from 'react';
import MenuItem from './MenuItem';

const menuItems = [
    {
        title: 'File',
        link: '/file/open/internally',
        subItems: [
            { title: 'Open Internally', link: '/file/open/internally' },
            { title: 'Open Externally', link: '/file/open/externally' },
            // { title: 'Add', link: '/file/add' },
            // { title: 'Save', link: '/file/save' }
        ]
    },
    {
        title: 'Tools',
        link: '/tools/unconnected',
        subItems: [
            { title: 'Find Unconnected Parts', link: '/tools/unconnected' },
        ]
    },
    // {
    //     title: 'View',
    //     link: '/view',
    //     subItems: [
    //         { title: 'Zoom In', link: '/view/zoom-in' },
    //         { title: 'Zoom Out', link: '/view/zoom-out' },
    //         { title: 'Full Screen', link: '/view/full-screen' }
    //     ]
    // },
    // ... (similar structure for Mode and Tools)
];

function Sidebar() {
    return (
        <div className="sidebar">
            <div className="menu-item">
                <a class="menu-button" href="/">Home</a>
            </div>
            {menuItems.map((item, index) => (
                <MenuItem key={index} {...item} />
            ))}
            <div className="menu-item">
                <a class="menu-button" href="/view/orientation">View Orientation</a>
            </div>
            <div className="menu-item">
                <a class="menu-button" href="/view/mode">View Mode</a>
            </div>
        </div>
    );
}

export default Sidebar;