import React from 'react';
import Content from '../components/Content';
import modesImg from '../images/mode/modes.jpg';
import modeShadedImg from '../images/mode/mode_shaded2.jpg';
import modeCombinedImg from '../images/mode/mode_combined2.jpg';
import modeWireframeImg from '../images/mode/mode_wireframe2.jpg';
import modePointsImg from '../images/mode/mode_points2.jpg';

function Mode() {
    return (
        <Content title="View Mode">
            <h3>Select one of the 4 view modes to change the rendering of the model(s) in the scene</h3>
            <img src={modesImg} alt="view modes" />
            <p>View mode: Shaded</p>
            <img src={modeShadedImg} alt="view mode shaded" />
            <p>View mode: Combined (Shaded + Wireframe)</p>
            <img src={modeCombinedImg} alt="view mode combined" />
            <p>View mode: Wireframe</p>
            <img src={modeWireframeImg} alt="view mode wireframe" />
            <p>View mode: Points</p>
            <img src={modePointsImg} alt="view mode points" />
        </Content>
    );
}

export default Mode;