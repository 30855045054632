import React from 'react';

function Content({ title, children }) {
    return (
        <div className="content">
            <h1>{title}</h1>
            {children}
        </div>
    );
}

export default Content;