import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SubMenu from './SubMenu';

function MenuItem({ title, subItems, link }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="menu-item">
            <Link to={link} className="menu-button" onClick={() => setIsOpen(!isOpen)}>
                {title}
            </Link>
            {isOpen && <SubMenu subItems={subItems} />}
        </div>
    );
}

export default MenuItem;