// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA86FoujWjei3IVY64tUd0N60yoIiifm_Y",
    authDomain: "fsvdemo001.firebaseapp.com",
    projectId: "fsvdemo001",
    storageBucket: "fsvdemo001.appspot.com",
    messagingSenderId: "317091666859",
    appId: "1:317091666859:web:afdf86ddcbd1c7aa1110ee",
    measurementId: "G-GYQ3S55KWB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, logEvent };