import React from 'react';
import { Link } from 'react-router-dom';

function SubMenu({ subItems }) {
    return (
        <div className="sub-menu">
            {subItems.map((item, index) => (
                <Link key={index} to={item.link} className="sub-menu-button">
                    {item.title}
                </Link>
            ))}
        </div>
    );
}

export default SubMenu;